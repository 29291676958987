import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";

import heroImage from "../../images/privacy-cookies/cover.jpg";
import { respH } from "../../lib/helpers";
import Seo from "../../components/seo";

import "./index.styles.scss";

const PrivacyCookies = ({ data }) => {
  const lastUpdatePrivacy = data.site?.siteMetadata?.lastUpdatePrivacy;
  const domain = data.site?.siteMetadata?.domain || "default domain";
  const mail = data.site?.siteMetadata?.mail || "default mail";
  const siteUrl = data.site?.siteMetadata?.siteUrl || "default url";

  return (
    <Layout pageClass="contatti">
      <Seo title="Privacy e cookies policy" />
      <section
        className="hero is-large"
        style={{
          backgroundImage: `linear-gradient( 109.6deg, rgba(156,252,248,0.3), transparent, rgba(110,123,251,0.3)), url(${heroImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {/* <div className='hero-body'> */}
        {/*   <h1 className={`title ${respH(1)} has-text-white has-text-centered is-capitalized`}>privacy e cookies policy </h1> */}
        {/* </div> */}
      </section>

      <section className="section is-medium pb-4">
        <div className="container has-max-ch mx-auto content">
          <h1 className={`title ${respH(2)}`}>Privacy e cookies policy</h1>
          <div className="block">
            <p>
              Questa è la <span className="is-italic">cookie policy</span> per{" "}
              {`${domain}`} accessibile all'indirizzo{" "}
              <a
                href={`${siteUrl}`}
                target="_blank"
                rel="noreferrer"
              >{`${siteUrl}`}</a>
            </p>
            <p>
              L'autore di questa <span className="is-italic">policy</span> e la
              persona responsabile per questo sito relativamente alle leggi di
              protezione dei dati è:
            </p>
          </div>
          <ul className="block">
            <li>La Fucina dei Talenti Srls</li>
            <li>Italy</li>
            <li>email: {`${mail}`}</li>
          </ul>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto">
          <h2 className={`title ${respH(3)}`}> Log di accesso</h2>
          <p>
            L'hosting su cui risiede questo sito è{" "}
            <a
              href="https://pages.github.com/"
              target="_blank"
              rel="noreferrer"
            >
              <u>GitHub Pages</u>
            </a>
          </p>
          <p>
            <strong>GitHub, Inc.</strong>
          </p>
          <p>
            GitHub Inc. registra il tuo indirizzo IP per 30 giorni dopo la
            giorni dopo la visita al sito. Questi dati sono utilizzati per le
            analisi di rete e non sono incrociati con altri dati identificativi.
            La base legale per questo utilizzo di dati è l'articolo 6 (1) (f)
            della GDPR. Questo sito tuttavia non ha la possibilità di accedere
            ai dati registrati da GitHub Inc.
          </p>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>Cookies</h2>
          <p>
            I cookie sono piccoli file che vengono scaricati sul tuo computer,
            per migliorare la tua visita o per altri scopi. Per maggiori
            informazioni sui cookies, vedi{" "}
            <a
              href="https://it.wikipedia.org/wiki/Cookie"
              target="_blank"
              rel="noreferrer noopener"
            >
              <u>Wikipedia su HTTP Cookies</u>
            </a>
            .
          </p>
          <p>
            L'utilizzo di questi cookies è subalterno (opt-In) al consenso dato
            dall'utente in apertura del sito con la{" "}
            <span className="is-italic">cookie banner.</span>
          </p>
          <p>
            In ogni momento puoi revocare il tuo consenso all'utilizzo dei
            cookies cliccando su{" "}
            <span className="is-italic">Cancella preferenze cookies</span> nel
            <span className="is-italic">footer del sito.</span> Questa azione
            cancella i cookies precedentemente salvati da questo sito.
          </p>
          <p>
            Puoi cancellare i cookies anche seguendo le istruzioni specifiche
            per il tuo <span className="is-italic">browser</span>
          </p>
          <ul className="list">
            <li className="list-item">
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=it"
                target="_blank"
                rel="noreferrer noopener"
              >
                Deleting cookies in Chrome
              </a>
            </li>
            <li className="list-item">
              <a
                href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                target="_blank"
                rel="noreferrer noopener"
              >
                Deleting cookies in Firefox
              </a>
            </li>
            <li className="list-item">
              <a
                href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                target="_blank"
                rel="noreferrer noopener"
              >
                Deleting cookies in Edge
              </a>
            </li>
          </ul>
          <p>
            Usiamo cookies di terze parti per analizzare l'utilizzo del sito e
            migliorare l'esperienza di navigazione futura.
          </p>
          <p>
            Questo sito usa Google Analytics: uno dei più diffusi software per
            l'analisi dell'utilizzo di un sito. I cookies impostati da Google
            Analytics possono tenere traccia del tempo che spendi sul nostro
            sito e delle pagine che visiti.
          </p>
          <p>
            Per maggiori informazioni su come Google utilizza i dati raccolti
            visita{" "}
            <a
              href="https://policies.google.com/technologies/partner-sites"
              target="_blank"
              rel="noreferrer"
            >
              <u>Google policies</u>
            </a>{" "}
            e su come vengono trattati i dati raccolti{" "}
            <a
              href="https://support.google.com/analytics/answer/6004245"
              target="_blank"
              rel="noreferrer"
            >
              <u>Google Analytics - privacy and security</u>
            </a>
          </p>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>Newsletter</h2>
          <p>
            Questo sito offre la possibilità di iscriversi a una newsletter, per
            ricevere informazioni e aggiornamenti.
          </p>
          <p>
            Per gestire la mailing list e la registrazione da parte degli utenti
            utilizziamo il servizio offerto da{" "}
            <a
              href="https://mailchimp.com/it/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Mailchimp
            </a>
            .
          </p>
          <p>
            Mailchimp ottempera alla GDPR. Per approfondimenti{" "}
            <a
              href="https://mailchimp.com/it/gdpr/"
              rel="noopener noreferrer"
              target="_blank"
            >
              leggi qui
            </a>
            .
          </p>
          <p>
            Quando l'utente clicca sul link "Iscriviti alla newsletter",
            presente in ogni pagina di questo sito, si apre un{" "}
            <span class="is-italic">form</span>, ospitato sui server di
            Mailchimp. In questo form chiediamo esplicitamente all'utente di
            dare il suo consenso all'invio dei dati strettamente necessari a
            ricevere periodicamente, sul proprio indirizzo di posta elettronica,
            le nostre mail con novità e aggiornamenti.
          </p>
          <p>
            Si noti che i dati inviati dagli utenti vengono condivisi con
            Mailchimp e che Mailchimp può salvare tali dati su{" "}
            <span class="is-italic">servers</span> che risiedono negli Stati
            Uniti d'America.
          </p>
          <p>
            Nota che il GDPR, che regola e tutela i dati degli utenti che
            risiedono nella EU, non richiede che i dati personali degli
            individui dell'UE siano conservati solo negli stati membri dell'UE.
          </p>
          <p>
            Tuttavia il GDPR richiede che determinate condizioni siano
            soddisfatte prima che i dati personali vengano trasferiti al di
            fuori dell'UE. In particolare che i dati siano protetti nel
            trasferimento e che il paese o territorio laddove i dati personali
            sono trasferiti fornisca un livello adeguato di protezione degli
            stessi.
          </p>
          <p>
            Mailchimp dichiara la sua conformità all'esportazione europea dei
            dati:
            <a
              href=" https://mailchimp.com/help/mailchimp-european-data-transfers"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              leggi qui
            </a>
          </p>
          <p>
            È importante notare che è sempre possibile rimuovere il propio
            contatto dalla newsletter e non ricevere più aggiornamenti.
          </p>
          <p>
            È sufficiente cliccare sul <span class="is-italic">link</span>{" "}
            <span class="has-text-weight-bold is-italic">Unsubscribe</span> che
            compare in calce alle mail che inviamo.
          </p>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>Altri utilizzi di dati</h2>
          <p>
            Quando l'utente invia una e-mail tramite il modulo di contatto
            presente nella pagina 'Contatti' o all'indirizzo riportato nel sito
            'info@fucinatalenti.it', vengono memorizzati i dati richiesti, per
            rispondere o per contattare in seguito l'utente. La persona non può
            inviare una richiesta di contatti, senza aver prima dato il consenso
            al trattamento dei dati. La base per questo trattamento dei dati è
            l'articolo 6 (1) (a) del GDPR.
          </p>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>I tuoi diritti</h2>
          <p>
            Se sei protetto dalla General Data Protection Regulation (GDPR) hai
            certi diritti relativamente ai tui dati personali. Questi diritti
            includono il diritto a...
          </p>
          <p>
            ...<strong>accedere</strong> a tutti i dati raccolti su di te,
            incluse le informazioni riguardanti il come condivido, custodisco e
            metto in sicurezza e utilizzo i tui dati.
          </p>
          <p>
            ...<strong>richiedere una correzione</strong> dei tuoi dati
            personali.
          </p>
          <p>
            ...<strong>richiedere la cancellazione</strong> dei tuoi dati
            personali, sotto certe limitazioni dovute alle leggi applicabili.
          </p>
          <p>
            ...<strong>contestare</strong> l'utilizzo dei tuoi dati personali
            presso le appropriate autorità competenti. (Per favore comunicatemi
            prima le vostre richieste, in modo da poter io stesso rispondere
            alle vostre preoccupazioni!)
          </p>
          <p>
            ...<strong>richiedere una limitazione</strong> a come i tuoi dati
            personali vengono utilizzati.
          </p>
          <p>
            ...<strong>trasferire</strong> i tuoi dati personali a un soggetto
            terzo.
          </p>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>Domande?</h2>
          <p>
            Per qualunque questione relativamente alla tua privacy o a questa{" "}
            <span className="is-italic">policy</span> puoi scrivere a{" "}
            {`${mail}`}
          </p>
          <p>Ultimo aggiornamento {`${lastUpdatePrivacy}`}</p>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyCookies;

export const query = graphql`
  query PrivacyPageQuery {
    site {
      siteMetadata {
        domain
        siteUrl
        mail
        lastUpdatePrivacy
      }
    }
  }
`;
